import { makeDeferred, deferred } from '../core';

class IconService {
  iconPromiseMap: ({[key: string]: deferred}) = { };
  iconSymbolMap: ({[key: string]: SVGSymbolElement}) = { };
  hasStickersheet: boolean = false;

  public async importStickersheet() {
    if (!this.hasStickersheet) {
      try {
        const stickerSheetString: string = (await import(`./icons.svg?raw`)).default;
        this.importSVG(stickerSheetString);
      } catch (e: unknown) {
        console.log((e as Error).message);
        return false;
      }
    }
    return true;
  }

  public importSVG(svgString: string) {
    try {
      const stickerSheetEl = document.createElement("div");
      stickerSheetEl.innerHTML = svgString;
      stickerSheetEl.querySelectorAll('symbol').forEach(el => {
        this.iconSymbolMap[el.id] = el;
        if (!(el.id in this.iconPromiseMap)) {
          this.iconPromiseMap[el.id] = makeDeferred();
        }
        this.iconPromiseMap[el.id].resolve();
      });
      this.hasStickersheet = true;
    } catch (e: unknown) {
      console.log((e as Error).message);
    }
  }

  public importSymbols(symbolStrings: string) {
    const stickerSheetEl = document.createElement("svg");
    stickerSheetEl.innerHTML = symbolStrings;
    stickerSheetEl.querySelectorAll('symbol').forEach(el => {
      this.iconSymbolMap[el.id] = el;
      if (!(symbolStrings in this.iconPromiseMap)) {
        this.iconPromiseMap[el.id] = makeDeferred();
      }
      this.iconPromiseMap[el.id].resolve();
    });
  }

  public getSymbols(name: string): SVGSymbolElement[] {
    const symbol = this.iconSymbolMap[name];
    if (symbol) {
      const useTag: SVGUseElement | null = symbol.querySelector('use');
      if (useTag) {
        const useTagRef = useTag.getAttribute('href') as string;
        
        return [symbol, ...this.getSymbols(useTagRef.slice(1))]
      }

      return [symbol];
    }

    return [];
  }

  public async waitForIcon(icon: string) {
    if (!(icon in this.iconPromiseMap)) {
      this.iconPromiseMap[icon] = makeDeferred();
    } 
    
    return await this.iconPromiseMap[icon].promise;
  }

}

export type IconServiceInstanceType = InstanceType<typeof IconService>;
export const iconService: IconServiceInstanceType = new IconService();
