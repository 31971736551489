import styles from './icon.scss?inline';
import { LitElement, html, nothing, unsafeCSS, CSSResult, svg } from 'lit';
import { Task } from '@lit/task';
import { property } from 'lit/decorators.js';
import { unsafeSVG } from 'lit/directives/unsafe-svg.js';
import { literal } from 'lit/static-html.js';
import { C11nPrefix, C11nPrefixLiteral } from 'virtual:c11n-prefix';
import { iconService } from './icon.service';
import { c11nElement } from '../core';

export type IconSize = 'small' | 'medium';

@c11nElement()
export class IconComponent extends LitElement {
  static selector = `${C11nPrefix}-icon`;
  static literal = literal`${C11nPrefixLiteral}-icon`;
  static override styles: CSSResult = unsafeCSS(styles);

  iconService = iconService;

  iconServiceTask = new Task(
    this, 
    {
      task: async ([icon]) => {
        return await this.iconService.waitForIcon(icon);
      },
      args: () => [this.getSymbolName()]
    }
  );
  
  // Some attributes (size, variant) were being stripped in Stencil apps,
  // but we found a simple workaround. If using @property 
  // we may need to differentiate the name from the attribute 
  // https://github.com/ionic-team/stencil/issues/2703
  
  @property({ attribute: 'alt-text', type: String, converter: (value: string | null) => value ?? '' })
  altText: string = '';
  
  @property({ attribute: 'name', type: String })
  _name: string = '';

  @property({ attribute: 'size', type: String, converter: (value: string | null) => value ?? 'small' })
  _size: IconSize = 'small';

  @property({ attribute: 'c11n-web-icon', type: Boolean, reflect: true })
  readonly _c11n_web_icon: boolean = true;

  getClassName() {
    if (this._name?.includes('stroke')) {
      return 'c11n-icon--stroke';
    }
    if (this._name?.includes('fill')) {
      return 'c11n-icon--fill';
    }
    if (this._size === 'medium') {
      return 'c11n-icon--stroke';
    }
    return 'c11n-icon--fill';
  }

  getStickersheet() {
    const symbols = this.iconService.getSymbols(this.getSymbolName());
    // Be cognizant that by adding line-breaks here, pesky whitespace will be created when icon utilizes display: inline-block
    return html`<div style="display: none">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 1000"
        width="1000"
        height="1000"
      >
        ${symbols.map((symbol: SVGSymbolElement) => svg`${unsafeSVG(symbol.outerHTML)}`)}
      </svg>
    </div>`;
  }

  getSymbolName() {
    return `${this._name}-${this._size}`;
  }

  getTitle() {
    if (this.altText !== '') {
      return svg`<title>${this.altText}</title>`;
    }
    return nothing;
  }

  getIcon() {
    const symbolName = this.getSymbolName();
    const symbols = this.iconService.getSymbols(symbolName);
    if (symbols.length > 0) {
      // Be cognizant that by adding line-breaks here, pesky whitespace will be created when icon utilizes display: inline-block
      return html`<svg 
          part="svg" 
          aria-hidden="${this.altText === '' ? true : nothing}" 
          role="${this.altText !== '' ? 'img' : nothing}" 
          focusable="${this.altText === '' ? false : nothing}" 
          class="${this.getClassName()}" 
          viewBox="${symbols[0].getAttribute('viewBox')}"
        >
          ${this.getTitle()}
          <use href="#${symbolName}">
        </svg>`;
    }
    return this.getPlaceholder();
  }

  getPlaceholder() {
    return html`<svg 
      part="svg" 
      aria-hidden="${this.altText === '' ? true : nothing}" 
      role="${this.altText !== '' ? 'img' : nothing}" 
      focusable="${this.altText === '' ? false : nothing}" 
      class="${this.getClassName()}" 
      viewBox="${this.getViewbox()}"
    >${this.getTitle()}</svg>`;
  }

  getViewbox() {
    if (this._size === 'small') {
      return '0 0 14 14';
    }
    return '0 0 24 24';
  }

  override render() {
    return this.iconServiceTask.render({
      pending: () => this.getPlaceholder(),
      // Be cognizant that by adding line-breaks here, pesky whitespace will be created when icon utilizes display: inline-block
      complete: () => html`${this.getStickersheet()}${this.getIcon()}`
    });
  }
}
export const C11nIcon = IconComponent.selector;
export const C11nIconLiteral = IconComponent.literal;
